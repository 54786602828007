.btn {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  padding: 10px;
  height: 40px;
  max-height: 40px;
  min-width: 112px;
  font-size: 17px;
  font-family: $font-roboto;
  font-weight: 500;
  line-height: 25px;
  border: none;
  border-radius: 4px;
  transition: all 0.2s ease-in-out;
  cursor: pointer;

  &--orange {
    background-color: $brand-color;
    color: $white;
  }

  &--light {
    background-color: #e8e8e8;
    color: black;
  }

  &--green {
    background-color: $green;
    color: $white;
  }

  &--danger {
    background-color: $danger;
    color: $white;
  }

  &--with-caret {
    justify-content: space-between;
  }

  &--disabled {
    opacity: 0.5;
    color: $white;
    cursor: default;
  }

  &--sm {
    color: $black;
    background: 0;
    min-width: 30px;
  }

  &--red {
    color: $danger;
  }
}

.page-btn {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  min-height: 100px;
  width: 100%;
  max-width: 300px;
  font-family: $font-open-sans;
  font-size: 21px;
  font-weight: 700;
  line-height: 25px;
  color: inherit;
  border-radius: 5px;
  transition: all 0.2s ease-in-out;
  cursor: pointer;
}

.builder-btn {
  min-height: 58px;
  font-size: 17px;
  font-weight: 500;
  line-height: 24px;
  position: relative;

  &--full {
    width: 100%;
  }

  &__progress {
    position: absolute;
    height: 100%;
    background-color: $brand-color;
    text-align: center;
    // width: 100%;
    border-radius: 4px;
    min-height: 48px;
    color: $white;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    z-index: 1;
    left: 0;
  }

  &__text {
    position: absolute;
    z-index: 2;
  }
}

@include respond-to(lg-down) {
  .page-btn {
    font-size: 1.2rem;
    min-height: 90px;
  }
}

@include respond-to(xs) {
  .page-btn {
    font-size: 1.1rem;
    min-height: 80px;
  }
}

.my-pages {
  .billsby-container {
    margin-bottom: 50px;
  }
  .page-title {
    font-size: 30px;
    font-weight: bold;
    margin-bottom: 30px;
    font-family: $font-roboto-slab;
  }

  .page-add-new {
    margin-top: 30px;
    display: flex;
    justify-content: flex-end;
  }
}

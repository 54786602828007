.page-404 {
  .section-404 {
    padding-bottom: 232px;
  }
  
  .container-404 {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .img-holder-404 {
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    margin-bottom: 30px;
  }

  .img-404 {
    // position: absolute;
    // top: 55px;
    // left: 85px;
    width: 100%;
  }

  .section-header__title {
    text-align: center;
  }
}
.page-main {
  margin: 0;
  section {
    &.section-cta {
      // padding-top: 30px;
      padding-bottom: 30px;
      margin-top: 50px;
      text-align: center;

      .page-btn {
        background-color: #ffffff;
        border: none;
      }
    }
  }

  .section-faq {
    padding-bottom: 50px;
  }

  p:last-child {
    margin-bottom: 10px;
  }
}

.test-page {
  #form {
    padding: 20px;

    #page-header {
      font-size: 32px;
      font-weight: bold;
      padding-bottom: 30px;
    }

    .test-form-group {
      display: flex;
      padding-bottom: 20px;
      .test-form-title {
        width: 200px;
        padding-right: 20px;
      }
      .test-color-scheme-field {
        padding: 10px;
        font-weight: 900;
      }
      .test-form-input {
        padding: 10px;
      }
    }
  }
}

.builder-input-label {
  font-size: 17px;
  line-height: 24px;
  font-weight: 700;
  font-family: $font-roboto;
}

.builder-input {
  padding: 10px 20px;
  min-height: 58px;
  box-shadow: 0px 0px 4px #00000029;
  border: none;
  border-radius: 4px;
  font-size: 17px;
  line-height: 24px;
  font-family: $font-roboto;

  &--full {
    width: 100%;
  }

  &--error {
    border: solid 2px $danger;
  }

  @include on-event {
    box-shadow: 0px 0px 4px #00000029 !important;
  }
}

.builder-editor {
  width: 100%;
}

@include respond-to(md-down) {
  .builder-input-label {
    margin-bottom: 8px;
  }
}

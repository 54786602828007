.page-gallery {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  margin-top: -13px;
  margin-left: -13px;

  &__img-holder {
    width: 300px;
    height: 300px;
    display: flex;
    margin-top: 13px;
    margin-left: 13px;
  }

  &__img-clickable {
    width: 100%;
    object-fit: cover;
    cursor: pointer;
  }
}

@media screen and (max-width: 1340px) {
  .page-gallery {
    &__img-holder {
      width: calc((100% / 2) - 13px);
    }
  }
}

@include respond-to(xs) {
  .page-gallery {
    &__img-holder {
      width: calc(100% - 13px);
    }
  }
}
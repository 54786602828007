.tooltips {
  background: #ffffff 0% 0% no-repeat padding-box !important;
  box-shadow: 0px 0px 25px #00000029;
  padding: 20px 30px !important;
  font-size: 17px !important;
  font-family: $font-roboto;
  &:after {
    left: 75% !important;
  }
}

/* Tooltip container */
.tooltip {
  position: relative;
  display: inline-block;

  /* Show the tooltip text when you mouse over the tooltip container */
  &__hover:hover {
    .tooltiptext {
      visibility: visible;
    }
  }

  &__active .tooltiptext {
    visibility: visible !important;
  }

  /* Tooltip text */
  .tooltiptext {
    font-size: 15px;
    visibility: hidden;
    width: 300px;
    background: #ffffff 0% 0% no-repeat padding-box;
    box-shadow: 0px 0px 25px #00000029;
    color: $black;
    text-align: center;
    padding: 15px 10px;
    border-radius: 6px;
    opacity: 1;

    /* Position the tooltip text - see examples below! */
    position: absolute;
    z-index: 100;

    //   top: -30px;
    bottom: 42px;
    right: -133%;

    &::after {
      content: " ";
      position: absolute;
      top: 100%; /* At the bottom of the tooltip */
      right: 18%;
      margin-left: -5px;
      border-width: 10px;
      border-style: solid;
      border-color: $white transparent transparent transparent;
      box-shadow: #272727c4;
    }

    .link {
      text-decoration: underline;
      cursor: pointer;

      &__danger {
        color: $danger;
      }
    }
  }
}

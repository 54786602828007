.builder-column {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;

  &--justify-start {
    justify-content: flex-start;
  }

  .builder-column-caption {
    font-size: 12px;
  }
}

.panel {
  &__label {
    position: relative;
    display: block;
    width: 100%;
    background: none;
    border: none;
    text-align: left;
    // padding: 25px 60px 25px 25px;
    margin-top: 30px;
    font-weight: 400;
    font-size: 21px;
    line-height: 25px;
    font-family: inherit;
    transition: color 0.2s linear;
    cursor: pointer;

    span {
      margin-left: 8px;
    }

    &:focus {
      outline: none;
    }
  }

  &:first-child {
    & .panel__label {
      margin-top: 0;
    }
  }

  &__inner {
    overflow: hidden;
    will-change: height;
    transition: height 0.4s cubic-bezier(0.65, 0.05, 0.36, 1);
  }

  &__content {
    margin: 30px 0 0 36px !important;
    font-size: 17px;
    line-height: 27px;
    opacity: 0;
    transition: opacity 0.3s linear 0.18s;
  }

  &[aria-expanded="true"] {
    .panel__content {
      opacity: 1;
    }
  }
}

@include respond-to(lg-down) {
  .panel {
    &__label {
      font-size: 1.2rem;
    }

    &__content {
      font-size: 17px;
    }
  }
}

@include respond-to(xs) {
  .panel {
    &__label {
      font-size: 1.1rem;
    }
  }
}

.color-picker {
  position: relative;

  &__btn {
    display: flex;
    align-items: center;
    padding: 0 20px;
    width: 142px;
    min-width: 142px;
    min-height: 58px;

    font-size: 17px;
    line-height: 24px;
    color: $white;

    font-family: "Roboto";

    box-shadow: 0px 0px 4px #00000029;
    border: none;
    border-radius: 4px;

    cursor: pointer;
  }

  &__popover {
    position: absolute;
    top: 0;
    left: 152px;
    z-index: 5;
  }

  &__cover {
    position: fixed;
    top: 0px;
    right: 0px;
    bottom: 0px;
    left: 0px;
    cursor: pointer;
  }

  .chrome-picker {
    font-family: "Roboto"!important;
    input {
      font-family: "Roboto";
    }
  }
}

@include respond-to(xs) {
  .color-picker {
    &__popover {
      top: 68px;
      bottom: 0;
      right: 0;
      left: 0;
    }
  }
}

.builder-tabs {
  display: flex;
  flex-direction: column;
  // overflow: hidden;
  border: none;
  background-color: transparent;
  font-family: $font-roboto;

  &__list {
    margin-bottom: 23px;
  }

  &__item {
    background-color: inherit;
    outline: none;
    border: 2px transparent;
    color: $charcoal;
    cursor: pointer;
    padding: 0;
    transition: all 0.2s ease-in-out;
    font-size: 17px;
    min-height: 28px;
    // max-height: 28px;
    font-family: inherit;
    box-sizing: border-box;
    text-align: left;

    @include on-event {
      color: $brand-color;
      border-bottom: 2px solid $brand-color;
    }

    &:not(:last-child) {
      margin-right: 25px;
    }

    &--active {
      color: $brand-color;
      font-weight: 700;
      border-bottom: 2px solid $brand-color;
    }
  }

  &__content {
    font-family: inherit;
    animation: fadeEffect 1s;
  }

  @-webkit-keyframes fadeEffect {
    from {opacity: 0;}
    to {opacity: 1;}
  }

  @keyframes fadeEffect {
    from {opacity: 0;}
    to {opacity: 1;}
  }
}
.section-header {
  font-family: $font-roboto;
  
  a {
    color: $brand-color;
  }

  &__title {
    font-family: $font-roboto-slab;
    margin-bottom: 20px;
    font-size: 30px;
    line-height: 1.1;
    font-weight: 700;
  }

  &__subtitle {
    margin-bottom: 20px;
    font-size: 17px;
    line-height: 24px;
    color: $charcoal;
  }
}

@include respond-to(lg-down) {
  .section-header {
    &__title {
      font-size: 1.6rem;
    }
  }
}
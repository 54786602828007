.builder-chart {
  position: relative;
  // border: 1px solid black;
  canvas {
    user-select: none;
  }

  // &::after {
  //   content: "";
  //   position: absolute;
  //   height: 20px;
  //   width: 50px;
  //   height: 15px;
  //   width: 90px;
  //   bottom: 0;
  //   background-color: #fff2ea;
  //   z-index: 99999999999;
  // }
  
  svg > g[class^="raphael-group-"] > text{
    display: none;
  }

  [class*="-plot-group"] rect[height="1"] {
    display: none;
  }
}
.parts {
  display: flex;

  &__img-holder {
  }

  &__img {
    display: block;
    width: 877px;
    height: auto;
    margin: -15px;
  }

  &__info {
    display: flex;
    flex-flow: row wrap;
    flex-grow: 1;
    padding-left: 35px;
  }
}

@media screen and (max-width: 1369px) {
  .parts {
    flex-direction: column;

    &__img {
      max-width: 100%;
      margin: 0 auto;
    }

    &__info {
      margin-top: -50px;
      margin-left: -50px;
      padding-top: 30px;
      padding-left: 10px;
    }
  }
}
.delete-page {
  padding-bottom: 175px; // 137 (footer height) + 38
  font-family: $font-roboto;

  .billsby-container-section:first-child {
    margin-bottom: 20px;
    .content-wrapper {
      .builder-text {
        margin-bottom: 20px;
      }
      .account-subscribe-btn {
        background: #fe7f2d 0% 0% no-repeat padding-box;
        border-radius: 4px;
        color: white;
        padding: 10px 20px;
        margin: 0;
      }

      .builder-radio-container {
        margin-bottom: 20px;
        display: flex;
        align-items: center;
        .builder-radio-btn {
          appearance: radio !important;
          box-sizing: border-box;
          height: 25px;
          width: 25px;
          color: black;
          margin-right: 20px;
        }
      }

      .button-group {
        display: flex;
        button {
          margin-right: 15px;
        }
      }
    }
  }

  .builder-text {
    margin-bottom: 0;
  }

  .btn {
    margin-top: 21px;
  }
}

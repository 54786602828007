.builder-row {
  display: flex;
  flex-direction: row;
  width: 100%;
  margin-top: 20px;

  &--two-column {
    & > .builder-column:first-child {
      min-width: calc(((24.5%) - 1px) + 1px);
      flex-basis: calc(((24.5%) - 1px) + 1px);
    }

    & > .builder-column:last-child {
      flex-grow: 1;
      align-items: flex-end;
    }
  }
  &--three-column {
    & > .builder-column:first-child {
      min-width: calc(((24.5%) - 1px) + 1px);
      flex-basis: calc(((24.5%) - 1px) + 1px);
    }
    & > .builder-column:nth-child(2) {
      min-width: calc(((50%) - 1px) + 1px);
      flex-basis: calc(((50%) - 1px) + 1px);
    }

    & > .builder-column:last-child {
      flex-grow: 1;
      justify-content: center;
      align-items: center;
      padding-left: 25px;
    }
  }
}

@include respond-to(md-down) {
  .builder-row {
    flex-wrap: wrap;

    &--two-column {
      & > .builder-column:first-child {
        flex-basis: 100%;
      }
    }
  }
}

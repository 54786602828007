.billsby-container {
  // max-width: 1340px;
  // min-width: 1340px;
  // margin: 0 auto;
  width: 100%;
  max-width: 1440px;
  margin: 0 auto;
  padding: 0 50px;
}

.page-container {
  max-width: 1340px;
  // min-width: 1240px;
  margin: 0 auto;
  padding: 0 50px;
}

.sample-container {
  max-width: 100%;
  margin: 0 auto;
  padding: 0 50px;
}

@include respond-to(sm-down) {
  .billsby-container {
    padding: 0 30px;
  }

  .page-container {
    padding: 0 30px;
  }
}

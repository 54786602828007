.builder-nav {
  font-family: $font-roboto;
  background-color: $peach;

  &__holder {
    position: relative;
    width: 100%;
    // padding: 20px 0;
    margin-bottom: 30px;
  }

  &__mobile-content {
    display: flex;
    // justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;
    z-index: 10;
    transition: all 0.2s ease-in-out;
  }

  &__item {
    position: relative;
    color: $charcoal;
    transition: all 0.2s ease-in-out;

    &:not(:first-child) {
      margin-left: 40px;
    }

    &--active {
      color: $brand-color;
      font-weight: 700;

      &::after {
        content: "";
        position: absolute;
        bottom: -4px;
        left: 0;
        height: 2px;
        width: 100%;
        background-color: $brand-color;
      }
    }

    @include on-event {
      color: $brand-color;
      // font-weight: 700;

      &::after {
        content: "";
        position: absolute;
        bottom: -4px;
        left: 0;
        height: 2px;
        width: 100%;
        background-color: $brand-color;
      }
    }
  }

  &__title {
    background-color: $brand-color-300;
    color: $white;
    font-weight: 500;
    display: flex;
    flex: 0 0 10%;
    padding-top: 20px;
    padding-bottom: 20px;
    position: relative;

    margin-left: -100%;
    padding-left: 100%;
    flex-grow: 1;

    span {
      position: relative;
      &::after {
        content: "";
        position: absolute;
        bottom: -4px;
        right: 0;
        height: 2px;
        width: 100%;
        background-color: $white;
        visibility: hidden;
        overflow: hidden;
      }

      &:hover {
        &::after {
          visibility: visible;
          -webkit-transform: scaleX(1);
          transform: scaleX(1);
        }
      }
    }

    span {
      cursor: pointer;
    }

    &:before {
      content: "";
      position: absolute;
      right: -47.8px;
      bottom: 16px;
      z-index: 1;
      border-left: 32px solid transparent;
      border-right: 32px solid transparent;
      border-top: 32px solid $brand-color-300;
      transform: rotate(270deg);
    }
  }

  &__first {
    display: flex;
    flex: 0 0 70%;
    padding-left: 70px;
  }
  &__second {
    display: flex;
    flex: 0 0 20%;
    justify-content: flex-end;
    .builder-nav__item:not(:first-child) {
      margin-left: 30px;
    }
  }

  &__custom-path-container {
    flex: 0 0 90%;
    // padding-left: 50px;
  }
  &__custom-path {
    background-color: $light-orange;
    font-weight: 500;
    display: flex;
    padding-top: 20px;
    padding-bottom: 20px;
    padding-left: 50px;
    padding-right: 10px;
    position: relative;
    max-width: 250px;

    &:before {
      content: "";
      position: absolute;
      right: -47.8px;
      bottom: 16px;
      z-index: 1;
      border-left: 32px solid transparent;
      border-right: 32px solid transparent;
      border-top: 32px solid $light-orange;
      transform: rotate(270deg);
    }
  }

  &__menu-btn {
    display: none;
    width: 100%;
    box-shadow: 0px 0px 4px #00000029;
    background-color: transparent;

    @include on-event {
      box-shadow: 0px 0px 4px #00000029;
    }
  }
}

@media screen and (max-width: 1100px) {
  .builder-nav {
    padding: 10px 0;
    margin-bottom: 20px;
    &__holder {
      margin-bottom: 0;
    }
    &__mobile-content {
      flex-direction: column;
      align-items: flex-start;
      padding: 0 0 15px 0;
      margin-top: 8px;
      box-shadow: 0px 0px 4px #00000029;
      border-radius: 4px;
      background-color: $white;
      width: 100%;
    }

    &__item {
      width: 100%;
      margin-bottom: 8px;

      &:not(:first-child) {
        margin-left: 0;
      }

      &:after {
        display: none;
      }
    }

    &__title {
      background-color: $brand-color-300;
      color: $white;
      font-weight: 500;
      display: flex;
      flex: 0 0 100%;
      padding: 10px 20px;
      width: 100%;
      border-radius: 4px 4px 0 0;

      margin-left: 0;
      margin-bottom: 20px;
      flex-grow: 1;

      &:before {
        display: none;
      }
    }

    &__first {
      // padding-left: 0;
      padding-bottom: 20px;
    }

    &__first,
    &__second {
      display: flex;
      flex-wrap: wrap;
      width: 100%;
      padding-left: 20px;
      padding-right: 20px;
    }

    &__second {
      .builder-nav__item:not(:first-child) {
        margin: 0;
      }
    }

    &__custom-path {
      width: 100%;
      padding: 0 0 0 20px;
      background: transparent;

      &:before {
        display: none;
      }
    }

    &__menu-btn {
      display: flex;
      background-color: $brand-color-300;
      color: white;
    }

    &__mobile-content {
      position: absolute;
      z-index: 100000;
    }
  }
}

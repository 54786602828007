.url-page {
  padding-bottom: 172px; // 137 (footer height) + 35
  font-family: $font-roboto;
  // font-size: 21px;
  // line-height: 28px;

  .builder-link {
    font-weight: 700;
    color: $charcoal;
    text-decoration: underline;
  }

  .links-holder {
    margin-bottom: 32px;
    
    .builder-link {
      display: block;
      font-weight: 400;

      &:not(:first-child) {
        margin-top: 6px;
      }
    }
  }
}
.logo {
  display: flex;
  // cursor: pointer;
  align-items: center;
  padding: 8px 0;

  &__img {
    max-height: 28px;
    width: auto;
  }

  &__text {
    margin-left: 4px;
    font-size: 21px;
    font-family: $font-chantal;
    color: $brand-color;
    letter-spacing: -0.42px;
    line-height: 24px;
    text-transform: uppercase;

    &--a {
      margin-right: 12px;
      font-family: $font-roboto;
    }

    &--project {
      margin-left: 8px;
      font-family: $font-roboto;
    }
  }
}

@include respond-to(xs) {
  // .logo {
  //   margin-bottom: 16px;
  // }
}

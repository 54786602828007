.help-page {
  // padding-bottom: 167px; // 137 (footer height) + 30
  .magic-block-image {
    img {
      width: 100%;
    }
  }
  .text-paragraph {
    margin-bottom: 20px;
  }
}

.builder-post-image {
  width: 100%;
  // border: 1px solid $charcoal;

  &__caption {
    margin-top: 32px;
    font-style: italic;
    text-align: center;
  }

  &__img {
    display: block;
    width: 100%;
    max-width: 600px;
    margin: 0 auto;
    height: auto;
    box-shadow: 0px 0px 10px #00000029;
  }
}

.image-placeholder {
  background-color: $gray;
  color: #5f5f5f;
  width: 100%;
  max-width: 900px;
  height: 456px;
  margin: 0 auto;
  position: relative;

  p {
    position: absolute;
    top: 50%;
    left: 50%;
    margin-right: -50%;
    transform: translate(-50%, -50%);
    font-style: italic;
    font-size: 36px;
  }
}
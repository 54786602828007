.edit-page {
  // margin-bottom: 182px;
  padding-bottom: 220px; // 137 (footer height) + 83
  section {
    &:not(:first-child) {
      margin-top: 40px;
    }

    // &:last-child {
    //   margin-bottom: 83px;
    // }
  }

  .section-header {
    .fields-wrapper {
      margin-top: 120px;
    }

    & .builder-row {
      margin-top: 20px;
    }
  }

  .section-faqs {
    & .builder-row {
      margin-top: 20px;
    }
  }

  .builder-row-group {
    padding-bottom: 40px;
  }

  .builder-column-group {
    width: 100%;
    display: flex;

    input {
      margin-right: 15px;
    }

    .faq-delete-btn {
      background: #d22630 0% 0% no-repeat padding-box;
      border-radius: 4px;
      color: white;
      border: 0;
      max-width: 342px;
      width: 100%;
      font-family: Roboto;
      font-weight: 500;
      font-size: 17px;
    }
  }

  .gallery-upload {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    margin-top: -14px;
    margin-left: -14px;

    &__img {
      width: 100%;
      object-fit: cover;
    }

    &__holder {
      margin-top: 14px;
      margin-left: 14px;
    }

    &__img-holder {
      width: 324px;
      height: 324px;
      position: relative;
      display: flex;
      align-items: center;
      justify-content: center;
      overflow: hidden;
    }

    .preview_gallery_image {
      position: absolute;
    }

    &__img-clickable {
      display: block;
      width: 100%;
      object-fit: cover;
      cursor: pointer;
    }

    &__btn-holder {
      margin-top: 30px;
      display: flex;
      flex-direction: column;
      align-items: center;
    }
  }

  .remove-btn {
    margin-top: 10px;
  }
}

@media screen and (max-width: 1438px) {
  .edit-page {
    .gallery-upload {
      &__holder {
        width: calc((100% / 2) - 14px);
      }

      &__img-holder {
        width: 100%;
      }
    }
  }
}

@include respond-to(xs) {
  .edit-page {
    .gallery-upload {
      &__holder {
        width: calc(100% - 14px);
      }
    }
  }
}

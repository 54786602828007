.login-page {
  padding-top: 30px;
  padding-bottom: 229px;
  font-family: $font-roboto;
  // font-size: 21px;
  line-height: 28px;

  .with-ext {
    flex-direction: row;

    .input-ext {
      display: flex;
      height: 100%;
      align-items: center;
      .builder-text {
        margin-left: 13px;
        margin-bottom: 0;
      }
    }
  }

  .btn {
    margin-top: 25px;
  }
  .signin-error-message {
    margin-top: 20px;
    display: flex;
    font-size: 21px;
    color: #d22630;
    justify-content: center;
  }
}

.home {
  // margin-bottom: 137px;

  .section-banner {
    min-height: 432px;
    padding-bottom: 45px;
    color: $white;
    background-color: $charcoal;
    background-image: url("../../assets/images/splode_2_black.svg");
    background-repeat: no-repeat;
    background-position: -184px -218px;

    .text-link {
      color: inherit;
      text-decoration: underline;
    }
  }

  .section-parts {
    padding: 50px 0;
  }

  .section-labs {
    padding: 54px 0;
    overflow: hidden;
    background-color: $brand-color;
    color: $white;

    .logo {
      align-items: center;
    }

    .logo__img {
      min-height: 39px;
      max-height: 39px;
    }

    .logo__text {
      margin-left: 6px;
      font-size: 29px;
      color: $white;
      letter-spacing: -0.58px;
      line-height: 33px;
    }
  }
}

body {
  color: $text-color;
  font: normal 17px / 25px $font-open-sans;
  letter-spacing: 0px;
}

.text {
  &--sm {
    font-size: 17px;
  }

  &--md {
    font-size: 30px;
  }

  &--lg {
    font-size: 36px;
  }
}

.builder-text {
  margin-bottom: 33px;
  font-size: 17px;
  line-height: 24px;
  font-family: $font-roboto;
}

.builder-link {
  font-size: 17px;
  line-height: 24px;
  color: $brand-color;
  font-family: $font-roboto
}

@include respond-to(lg-down) {
  .text {
    &--lg {
      font-size: 30px;
    }
  }
}
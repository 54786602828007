.header {
  background-color: $charcoal;

  &__content {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    align-items: center;
    padding: 8px 0;
    min-height: 77px;
  }

  &__button {
    padding: 8px 0;
  }

  &--transparent {
    position: absolute;
    left: 0;
    top: 0;
    background-color: transparent;
    width: 100%;

    .logo__text {
      color: $charcoal;
    }
  }
}

.preview-header,
.page-header {
  position: relative;
  min-height: 379px;
  color: $white;
  font-family: $font-open-sans;

  &__background,
  &__overlay {
    position: absolute;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
  }

  &__background {
    background-size: cover;

    z-index: 1;
  }

  &__overlay {
    background-color: $charcoal;
    opacity: 0.5;

    z-index: 2;
  }

  &__content {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    min-height: 379px;
    height: 100%;
    padding: 0 16px;

    z-index: 3;
  }

  &__logo {
    display: flex;
    align-items: center;
    flex-grow: 1;
    padding-bottom: 10px;

    img {
      display: block;
      // min-height: 78px;
      // max-height: 150px;
      max-width: 348px;
      width: 100%;
      padding: 10px; // just for demo
      // background-color: $white; // just for demo
      border-radius: 4px; // just for demo
    }
  }

  &__text {
    padding-bottom: 100px;
    font-size: 30px;
    font-weight: 700;
    line-height: 33px;
    letter-spacing: 0px;
    text-align: center;
  }

  &__buttons {
    position: absolute;
    bottom: -87.5px;
    display: flex;
    flex-direction: column;
    // justify-content: center;
    align-items: center;
    left: 50%;
    width: 100%;
    padding: 0 8px;
    transform: translate(-50%, 0);
  }

  &__subscribe-btn {
    display: inline-flex;
    justify-content: center;
    align-items: center;
    min-height: 100px;
    width: 100%;
    max-width: 300px;
    font-size: 21px;
    font-weight: 700;
    line-height: 25px;
    color: inherit;
    border: 5px solid $white;
    border-radius: 5px;
    transition: all 0.2s ease-in-out;
    text-align: center;
    padding: 16px 16px;
    word-break: break-all;
    font-family: "Open Sans", sans-serif;
  }

  &__account-btn {
    margin-top: 15px;
    font-size: 17px;
    color: inherit;
    line-height: 27px;
    letter-spacing: 0;
    text-align: center;
    text-decoration: underline;
  }
}

.page-header {
  &__logo-img {
    cursor: pointer;
  }
}

@include respond-to(lg-down) {
  .header {
    &__button .btn {
      box-shadow: 0px 0px 4px #00000029;
    }
  }

  .preview-header,
  .page-header {
    &__text {
      font-size: 30px;
    }

    &__buttons {
      bottom: -82.5px;
    }

    &__subscribe-btn {
      font-size: 1.2rem;
      min-height: 90px;
    }

    &__account-btn {
      font-size: 1.2rem;
    }
  }
}

@include respond-to(xs) {
  .header {
  
    &__button {
      width: 100%;

      .btn{
        display: flex;
        width: 100%;
      }
    }
  }

  .preview-header,
  .page-header {

    &__buttons {
      bottom: -77.5px;
    }

    &__subscribe-btn {
      font-size: 1.1rem;
      min-height: 80px;
    }

    &__account-btn {
      font-size: 1.1rem;
    }
  }
}

.page-processing {
  .section-processing {
    padding-top: 0 !important;
    .billsby-container {
      display: flex;
      align-items: center;
      flex-direction: column;

      .content-wrapper {
        .builder-text {
          margin-top: 0;
        }

        .processing-image {
          position: relative;
          top: 40px;

          img {
            width: 100%;
          }
        }
      }
    }
  }
}

.page-privacy {

  .section-privacy {
    &__title {
      margin: 30px 0;
      font-size: 36px;
      font-weight: 700;
      line-height: 42px;
      letter-spacing: 0px;
    }
  }
}

@include respond-to(lg-down) {
  .page-privacy {
    .section-privacy {
      &__title {
        font-size: 30px;
      }
    }
  }
}
.builder-editor {
  background: #FFFFFF 0% 0% no-repeat padding-box;
  box-shadow: 0px 0px 4px #00000029;
  border-radius: 4px;
  font-family: $font-roboto;
  line-height: 1.4;
  padding: 20px;
  min-height: 315px;

  blockquote {
    border-left: 2px solid #ddd;
    margin-left: 0;
    margin-right: 0;
    padding-left: 10px;
    color: #aaa;
    font-style: italic; 
  }

  &__toolbar {
    position: relative;
    border-bottom: 2px solid #EFEFEF;
    padding: 1px 18px 17px;
    margin: 0px -18px 20px;
    
    & > * {
      display: inline-block;
    }

    & > * + * {
      margin-left: 15px;
    }
  }

  [data-slate-editor] > * + * {
    margin-top: 1em;
  }

  &--w-1048 {
    max-width: 1048px;
  }
}
.footer {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  background-color: $charcoal;
  text-align: left;
  z-index: 99999;
}

.edit-footer {
  bottom: 0;
  left: 0;
  width: 100%;
  background-color: $charcoal;
  text-align: left;
}

.copyright {
  display: flex;
  flex-direction: column;
  justify-content: center;
  min-height: 137px;

  &__text {
    color: $gray;
    font-family: $font-roboto;
    font-size: 14px;
    line-height: 17px;
  }

  &__link {
    color: $white;
    text-decoration: underline;
  }
}

.page-footer {
  // margin-top: 50px;
  padding: 30px;
  color: $white;
  // position: absolute;
  // bottom: 0;
  // left: 0;
  width: 100%;
  // z-index: 99999;
  margin-top:auto; 

  &__content {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }

  &__social {
    margin-bottom: 16px;
    font-size: 36px;
    line-height: 36px;

    a {
      color: $white;

      &:not(:first-child) {
        margin-left: 16px;
      }
    }
  }

  &__copyright {
    font-size: 17px;
    line-height: 27px;
  }

  &__links {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    width: 100%;
    // margin-top: -25px;
    margin-left: -25px;

    a {
      // margin-top: 25px;
      display: inline-block;
      margin-left: 25px;
      font-size: 17px;
      line-height: 27px;
      color: $white;
      text-align: center;
      text-decoration: underline;
      word-break: break-all;
      // overflow:hidden;
      // text-overflow:ellipsis;
    }
  }
}

@include respond-to(xs) {
  .page-footer {
    &__social {
      font-size: 30px;
      line-height: 30px;
    }

    &__copyright {
      font-size: 17px;
    }

    &__links {
      a {
        font-size: 17px;
      }
    }
  }
}

.notification-container {
  font-size: 14px;
  box-sizing: border-box;
  position: fixed;
  z-index: 999999;
  transform: translate(-50%, 0px);
  left: 50%;
}

.top-right {
  top: 12px;
  right: 12px;
  transition: transform 0.6s ease-in-out;
  animation: toast-in-right 0.7s;
}

.bottom-right {
  bottom: 12px;
  right: 12px;
  transition: transform 0.6s ease-in-out;
  animation: toast-in-right 0.7s;
}

.top-left {
  top: 12px;
  left: 12px;
  transition: transform 0.6s ease-in;
  animation: toast-in-left 0.7s;
}

.bottom-left {
  bottom: 12px;
  left: 12px;
  transition: transform 0.6s ease-in;
  animation: toast-in-left 0.7s;
}

.top-center {
  top: 12px;
  transition: transform 0.6s ease-in;
  animation: toast-in-top 0.7s;
}

.notification {
  background: #fff;
  transition: 0.3s ease;
  position: relative;
  pointer-events: auto;
  overflow: hidden;
  margin: 0 0 6px;
  padding: 30px;
  margin-bottom: 15px;
  width: 300px;
  max-height: 100px;
  border-radius: 3px 3px 3px 3px;
  box-shadow: 0 0 10px #999;
  color: #000;
  opacity: 1;
  background-position: 15px;
  background-repeat: no-repeat;
  display: flex;
  align-items: center;
}

.notification:hover {
  // box-shadow: 0 0 12px #fff;
  opacity: 1;
  cursor: pointer;
}

.notification-image-holder {
  margin-right: 30px;

  .notification-image {
    object-fit: cover;
    width: 270px;
    height: auto;
    margin-top: 20px;
    margin-left: -70px;

    &.billsbot-down {
      width: 231px;
      height: auto;
      margin-top: 73px;
    }

    &.billsbot-elated {
      width: 231px;
      height: auto;
      margin-top: 10px;
    }
  }
}

.notification-content {
  flex-grow: 2;
  display: flex;
  flex-direction: column;
  justify-content: center;
  .notification-title {
    font-weight: 700;
    font-size: 20px;
    text-align: left;
    margin-top: 0;
    margin-bottom: 6px;
    width: 100%;
    height: 18px;
    font-family: "Roboto Slab";
  }

  .notification-message {
    margin: 0;
    text-align: left;
    height: 18px;
    margin-left: -1px;
    text-overflow: ellipsis;
    white-space: nowrap;
    font-family: "Roboto";
    font-size: 16px;
  }
}

.toast {
  height: 100px;
  width: 640px;
  padding: 10px;
  //   color: #fff;
  // padding: 20px 15px 10px 10px;
}
.toast-link {
  color: #fe7f2d;
  text-decoration: underline;
}

.notification-container button {
  position: relative;
  // right: -0.3em;
  // top: -0.3em;
  float: right;
  font-weight: 700;
  //   color: #fff;
  outline: none;
  border: none;
  text-shadow: 0 1px 0 #fff;
  opacity: 0.8;
  line-height: 1;
  font-size: 16px;
  padding: 0;
  cursor: pointer;
  background: 0 0;
  border: 0;
  color: #e4e4e4;
  width: 30px;
}

@keyframes toast-in-right {
  from {
    transform: translateX(100%);
  }
  to {
    transform: translateX(0);
  }
}

@keyframes toast-in-left {
  from {
    transform: translateX(-100%);
  }
  to {
    transform: translateX(0);
  }
}

@keyframes toast-in-top {
  from {
    transform: translateY(0);
  }
  to {
    transform: translateY(5%);
  }
}

.builder-table {
  border: none;
  box-shadow: 0px 0px 4px #00000029;
  border-radius: 4px;
  border-collapse: collapse;
  margin: 0;
  padding: 0;
  width: 100%;
  table-layout: fixed;
  margin-bottom: 25px;
  overflow: hidden;

  th,
  td {
    padding: 20px;
    text-align: left;
  }

  th {
    font-size: 17px;
    font-weight: 700;
  }

  tr:not(:last-child) {
    border-bottom: 2px solid #EFEFEF;
  }
  
  tbody tr {
    &:hover {
      background-color: #EFEFEF;
    }
  }
  thead {
    background-color: $brand-color;
    color: $white;
  }
}

@media screen and (max-width: 600px) {
  .builder-table {
    border: 0;
    box-shadow: unset;
    overflow: unset;

    thead {
      border: none;
      clip: rect(0 0 0 0);
      height: 1px;
      margin: -1px;
      overflow: hidden;
      padding: 0;
      position: absolute;
      width: 1px;
    }

    tbody tr {
      &:hover {
        background-color: $white;
      }
    }

    tr {
      display: block;
      box-shadow: 0px 0px 4px #00000029;
      border-radius: 4px;
      
      &:not(:last-child) {
        margin-bottom: 20px
      }
    }

    td {
      border-bottom: 2px solid #EFEFEF;
      display: block;
      font-size: 17px;
      text-align: right;
    }

    td::before {
      /*
      * aria-label has no advantage, it won't be read inside a table
      content: attr(aria-label);
      */
      font-size: 17px;
      content: attr(data-label);
      float: left;
      font-weight: bold;
    }

    td:last-child {
      border-bottom: 0;
    }
  }
}
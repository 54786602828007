.parts-label {

  &:nth-child(1) {
    margin-top: 0;
  }

  &:nth-child(2) {
    margin-top: 25px;
  }

  &:nth-child(3) {
    margin-top: 25px;
  }

  &:nth-child(4) {
    margin-top: 154px;
  }

  &:nth-child(5) {
    margin-top: 144px;
  }

  &:nth-child(6) {
    margin-top: 184px;
  }

  &__title {
    margin-bottom: 25px;
    font-family: $font-chantal;
    letter-spacing: -0.72px;
    font-size: 36px;
    line-height: 44px;
    font-weight: 500;
  }

  &__subtitle {
    font-size: 17px;
    font-family: $font-roboto;
  }
}


@media screen and (max-width: 1369px) {
  .parts-label {
    margin-top: 50px;
    margin-left: 50px;

    width: calc((100% / 2) - 50px);

    &:nth-child(1) {
      margin-top: 50px;
    }
  
    &:nth-child(2) {
      margin-top: 50px;
    }
  
    &:nth-child(3) {
      margin-top: 50px;
    }
  
    &:nth-child(4) {
      margin-top: 50px;
    }
  
    &:nth-child(5) {
      margin-top: 50px;
    }
  
    &:nth-child(6) {
      margin-top: 50px;
    }
  }
}

@include respond-to(md-down) {
  .parts-label {
    &__title {
      font-size: 30px;
    }
  }
}

@include respond-to(sm-down) {
  .parts-label {
    width: calc(100% - 50px)
  }
}
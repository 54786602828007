.custom-table {
  overflow: hidden;

  border-radius: 4px;
  box-shadow: 0px 0px 4px #00000029;

  &.pages-table {
    .tr {
      .th {
        font-weight: 600;
        background: #fe7f2d;
        color: white;
        &:last-child {
          color: #fe7f2d;
          //   padding: 32px;
        }
      }
    }

    .th,
    .td {
      padding: 20px;
      &:first-child {
        flex: 0 0 20%;
        max-width: 20%;
      }
      &:not(:first-child) {
        flex: 0 0 55%;
        max-width: 55%;
      }
      &:last-child {
        display: flex;
        justify-content: flex-end;
        flex: 0 0 25%;
        max-width: 25%;
      }

      .builder-link {
        color: $black;
        text-decoration: underline;
      }
    }
  }

  .tr {
    display: flex;
    align-items: center;

    background-color: #fff;

    &.no-data {
      padding: 25px;
      text-align: center;
      display: block;
      font-size: 16px;
    }

    .th {
      position: relative;
    }
  }

  .th,
  .td {
    flex: 0 0 20%;
    padding: 20px;
    max-width: 20%;

    font-size: 17px;
    font-weight: 400;

    overflow-wrap: anywhere;

    &:not(:nth-child(-n + 2)) {
      padding-left: 39px;
    }
  }

  .td {
    background-color: white;
  }
}

.landing-banner {
  padding-top: 77px;

  &__title {
    margin-top: 20px;
    max-width: 795px;
    font-family: $font-roboto-slab;
    line-height: 42px;
    font-weight: 700;
  }

  &__subtitle {
    margin-top: 32px;
    max-width: 727px;
    font-family: $font-roboto;
    line-height: 25px;
    font-weight: normal;
  }

  &__logo {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    margin-top: 30px;

    .logo__text {
      color: $white;
    }
  }
}

@include respond-to(lg-down) {
  .landing-banner {
    padding-top: 127px;

    &__title {
      line-height: 32px;
    }
  }
}

.filter-options {
  margin-bottom: 20px;
  margin-top: 10px;

  .react-select {
    &__control {
      padding: 15px;
    }
    &__value-container {
      padding: 0;
    }
    &__indicator-separator {
      display: none;
    }
    &__indicator {
      color: $black;
      transform: scale(1.2);
      padding: 0 0 0 0;
    }
    &__option {
      padding: 15px;
    }
  }
}

*,
*:before,
*:after {
  margin: 0;
  // padding: 0;
  box-sizing: border-box;
}

a,
button {
  cursor: pointer;
}

a {
  text-decoration: none;
}

.btn,
a {
  transition: all 0.2s ease-in-out;
  -webkit-transition: all 0.2s ease-in-out;

  @include on-event {
    outline: 0;
    box-shadow: none;
  }
}

/**
 * Basic styles for input fields
 */
input[type="email"],
input[type="password"],
input[type="text"] {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  -webkit-focus-ring-color: none;
  outline: none;

  @include on-event {
    box-shadow: none;
  }
}

input,
select,
button,
textarea {
  outline: none;
  @include appearance(none);

  &::-webkit-outer-spin-button,
  &::-webkit-inner-spin-button {
    @include appearance(none);
  }
}

.hide {
  display: none;
  opacity: 0;
  visibility: hidden;
}

.show {
  opacity: 0;
  visibility: hidden;

  &--block {
    display: block;
  }

  &--flex {
    display: flex;
  }

  &--inline {
    display: inline;
  }

  &--inline-flex {
    display: inline-flex;
  }
}

main[class$="-page"] {
  font-size: 17px;
  line-height: 24px;
  font-family: $font-roboto;

  p, ul, ol {
    margin-bottom: 33px;
  }

  a {
    color: $brand-color;
  }
}

main[class^="page-"] {
  // margin-top: 90px;
  font-family: $font-open-sans;
  font-size: 17px;
  line-height: 24px;
  padding-bottom: 0;
  color: $black;

  p {
    margin-top: 27px;
    margin-bottom: 27px;
  }

  h1,
  h2 {
    margin-top: 30px;
    margin-bottom: 30px;
    font-weight: 700;
  }

  h1 {
    font-size: 30px;
    line-height: 33px;
  }

  h2 {
    font-size: 28px;
    line-height: 30px;
  }

  ol,
  ul {
    margin-left: 1.1em;
  }

  section {
    &:first-child {
      padding-top: 110px;
    }

    &:not(:first-child) {
      padding-top: 50px;
    }

    &.section-cta {
      padding-top: 30px;
    }
  }

  blockquote {
    border-left: 2px solid #ddd;
    margin-left: 0;
    margin-right: 0;
    padding-left: 10px;
    color: #aaa;
    font-style: italic;
  }
}

#root {
  position: relative;
  min-height: 100vh;
}

main {
  padding-bottom: 137px;
  height: 100%;
}

.pages-app-container {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

// .App {
//   height: 100%;
// }


@include respond-to(lg-down) {
  main[class^="page-"] {
    font-size: 17px;

    h1 {
      font-size: 30px;
      line-height: 42px;
    }
  
    h2 {
      font-size: 24px;
      line-height: 36px;
    }
  }
}

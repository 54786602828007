.labs {
  display: flex;
  justify-content: space-between;
  align-items: center;

  &__text-content {
    max-width: 800px;
  }

  &__img-holder {
    position: relative;
  }

  &__img {
    position: absolute;
    top: -212px;
    right: -102px;
    bottom: 0
  }

  &__title {
    margin-top: 15px;
    font-family: $font-roboto-slab;
    line-height: 42px;
    font-weight: 700;
  }

  &__subtitle {
    margin-top: 16px;
    font-family: $font-roboto;
  
    &:last-child {
      margin-top: 26px;
    }
  }
}

@media screen and (max-width: 1380px) {
  .labs {
    flex-direction: column;
    align-items: flex-start;

    &__text-content {
      max-width: 100%;
    }

    &__img-holder {
      position: relative;
      padding-top: 30px;
    }

    &__img {
      position: unset;
      max-width: 100%;
      margin-bottom: -61px;
    }
  }
}
